import Badge from 'react-bootstrap/Badge';
import { MONITOR_TITLE } from '../utils/constants';


const styles = {
    "engieOn":{
        padding:6, 
        borderColor:'white', 
        borderWidth:1, 
        borderStyle:'solid',
        letterSpacing:1,
        fontSize:12
    },
    "engieOff":{
        padding:6, 
        borderColor:'white', 
        borderWidth:1, 
        borderStyle:'solid',
        letterSpacing:0,
        fontSize:10,
        color:'#f5f5f5',
        fontWeight:'400'
    },
    "upTag":{
        paddingTop:6, 
        borderColor:'white', 
        borderWidth:1, 
        borderStyle:'solid'
    },
    "upLabel": {
        letterSpacing:1, 
        marginLeft:5,
        marginTop:5,
        paddingTop:5
    }
}


export function numberCellFormatter(params) {
    return (Math.round(params.value * 100) / 100).toFixed(2).toString();
}


export function getIconeClass(params) {
    if (params.data) {
        const timeout = params.data.atualizado.getTime() + (15 * 60 * 1000); //15 min
        if (params.data.deviceState === "warning" || (params.data.deviceState === "running" && params.data.controleSistema === 1 && Date.now() > timeout) ) {
            return "bi-tools text-light";
        } else if (params.data.deviceState === "offline") {
            return "bi-wifi-off";
        } else {
            return null;
        }
    }
}


export function getOfflineTime(params) {
    if(params.node?.allLeafChildren
            && params.node.allLeafChildren[0].data?.group !== undefined
            && params.node.allLeafChildren[0].data.group?.deviceState !== undefined
            && params.node.allLeafChildren[0].data.group?.deviceState === "offline"){
        const dt = new Date();
        // Calcula a diferença em milissegundos entre as duas datas
        const diferenca = Math.abs(dt - params.node.allLeafChildren[0].data.group.deviceStateAt);
        // Calcula os dias, horas e minutos
        const horas = Math.floor(diferenca / (1000 * 60 * 60)).toString().padStart(3, ' ');
        const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, ' ');
        // Retorna o resultado formatado
        return `${horas} h e ${minutos} min`;
    } else {
        return null;
    }
}


export function hideTagLabel(value, tag) {
    if(typeof value === "number"){
        const txt = ": "+value.toFixed(2)+"\n\nErro no sensor. Solicite manutenção";
        return  {
            component: () => (
                <h6>
                    <Badge variant="secondary" title={tag + txt}>
                        <TagIcon name="hide"/>
                    </Badge>
                </h6>)
        }
    }
}


const tagIcons = {
    "alert":<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" className="bi bi-exclamation-triangle" viewBox="0 0 16 16" style={{marginBottom:3}} >
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
            </svg>,
    "dpv":<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" className="bi bi-exclamation-triangle" viewBox="0 0 16 16" style={{marginBottom:3}} >
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
            </svg>,
    "temperatura":<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" className="bi bi-thermometer-half" viewBox="0 0 16 16" style={{marginBottom:3}}>
                <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z"/>
                <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z"/>
            </svg>,
    "umidade":<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" className="bi bi-droplet" viewBox="0 0 16 16" style={{marginBottom:3}}>
                <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10a5 5 0 0 0 10 0c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
                <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"/>
            </svg>,
    "warning": <svg style={{marginBottom:3}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-tools" viewBox="0 0 16 16">
                    <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z"/>
                </svg>,
    "info":<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16" style={{marginLeft:4, marginBottom:1, opacity:0.7}}>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
            </svg>,
    "hide": <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
            </svg>,
    "offline":<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" className="bi bi-wifi-off" viewBox="0 0 16 16"  style={{marginBottom:3}}>
                    <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
            </svg>,
    "no_state":<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" className="bi bi-hourglass" viewBox="0 0 16 16" style={{marginBottom:3}}>
                    <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5m2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2z"/>
            </svg>
}


export const mainIcons = {
    "logout":<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="silver" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
            </svg>,
    "google":<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google" viewBox="0 0 16 16" 
                    style={{marginRight:10, marginBottom:3,}}>
                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
            </svg>,
    "backNavBar":<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="silver" className="bi bi-chevron-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
            </svg>
}


const noIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14Z"/>
                </svg>


export const MainIcon = ({name}) => {
    if(!mainIcons[name]) return noIcon;
    return mainIcons[name];
};

const TagIcon = ({name}) => {
    if(!tagIcons[name]) return tagIcons['alert'];
    return tagIcons[name];
};



function upMaxTagLabel(value, msg = 'Sem target', target = null, max = null, tag, color = "crimson") {
    let alertMsq = '';
    const stBadge = {
        backgroundColor:color,
        paddingTop:6, 
        borderColor:'white', 
        borderWidth:1, 
        borderStyle:'solid'
    };
    if(target && typeof target === "object"){
        for(const key in target){
            if(target[key]){
                alertMsq += key+': '+target[key].toFixed(2)+'\n';
            }
        }
    }else{
        if(target && max){
            alertMsq += 'Max: '+max.toFixed(2);
            alertMsq += '\nTarget: '+target.toFixed(2);
        }else{
            alertMsq += msg;
        }
    }
    return (
        <h6>
            <Badge variant="danger" style={stBadge} title={alertMsq} >
                <TagIcon name={tag}/>
                {value &&
                    <span style={styles.upLabel}  >
                        {typeof value === "number" ? value.toFixed(2) : value}
                    </span>
                }
            </Badge>
        </h6>
    );
}


export function tagLabel(tag, msg = "") {    
    return  {
        component: () => (
            <div style={{paddingBottom:5,}} title={msg} >
                <TagIcon name={tag}/>
            </div>)
    }
}


export function textLabel(value, msg = "") {    
    return  {
        component: () => (
            <div style={{paddingBottom:5,fontWeight:'600'}} title={msg} >
                {value}
            </div>)
    }
}


export function offlineTagLabel(info, timeAt) {  
    const diffMin = Math.floor( (Math.abs((new Date()) - timeAt)) / (1000 * 60 ) ).toString().padStart(3, ' ');
    return   {
        component: () => upMaxTagLabel("", `UTAR Offiline há ${Math.round(diffMin/60)} h e ${diffMin%60} min\n${info}`, null, null, "offline")
    }
}


export function noStateTagLabel(info, timeAt) {  
    const diffMin = Math.floor( (Math.abs((new Date()) - timeAt)) / (1000 * 60 ) ).toString().padStart(3, ' ');
    return   {
        component: () => upMaxTagLabel("", `UTAR não envia dados há ${diffMin%60} min\n${info}`, null, null, "no_state", "silver")
    }
}


export function warningGroupTagLabel(total) {  
    const msg = total.toString() + ' amostra'+((total && total > 1) ? 's' : '')+' com erro';
    return  {
        component: () => upMaxTagLabel(total.toString(), msg, null, null, "warning")
    }
}


export function warningTagLabel(info, warning) {  
    return {
        component: () => {
            let msgInfo = info + '\n\n';
            let module = '';
            if(warning.length > 0){
                for(const warn of warning){
                    if(module !== warn.module_id.split('_')[1]){
                        module = warn.module_id.split('_')[1];
                        msgInfo += warn.module_id.split('_')[1] + ' - ';
                        // msgInfo += warn.name + ': ';
                        msgInfo += typeof warn.warning_msg === "object" ? JSON.stringify(warn.warning_msg) : warn.warning_msg ;
                        msgInfo += '\n\n';
                    }
                }
            }
            return upMaxTagLabel("", msgInfo, null, null, "warning");
        }
    }
}


export function engieDataExtractor(params) {  
    if(typeof params?.value === "number"){
        return { "control":params.value , "info":"" };
    }else{
        if(params?.value && typeof params.value?.value === "number"){
            return  {"control": params.value.value , "info":"Atualizado em: "+params.node.allLeafChildren[0].data.group.controleMotorAt };
        }else{
            return  {"control": null , "info":"" };
        }
    }
}


const engieControl = [
    {"variant":"secondary", "tag":"Desligado", "style":styles.engieOff },
    {"variant":"success", "tag":"Ligado", "style":styles.engieOn }
];


export function engieTagLabel(control, info = "") {  
    if(typeof control === "number" && control < 2){
        return  {
            component: () => (
                <Badge variant={engieControl[control].variant} title={info} style={engieControl[control].style}>
                    {engieControl[control].tag}
                </Badge>
            )
        }
    }else{
        return  {
            component: () => (<span ></span>)
        }
    }
}

export let devicesWarm = {};
export let devicesWarmLineColor = {};
export let lineWarn = {};

export function upMaxTag(params, key) {
    // Grupo
    if(params?.value && typeof params.value?.value === "number"){

        if(params.node.allLeafChildren[0].data.group.controleSistema === 0
                && params.node.allLeafChildren[0].data.group.colors_palette?.main_reference_control_off){
            return  {
                component: () => upMaxTagLabel(params.value.value, null, params.node.allLeafChildren[0].data.group.references[key], 
                    params.node.allLeafChildren[0].data.group.references[key].max, key, 
                    params.node.allLeafChildren[0].data.group.colors_palette?.main_reference_control_off)
            }
        }

        // Valor acima do Max
        if(params.node.allLeafChildren[0].data.group.references !== undefined
                && params.node.allLeafChildren[0].data.group.references[key] !== undefined
                && params.node.allLeafChildren[0].data.group?.references[key]?.max !== null
                && params.value.value > params.node.allLeafChildren[0].data.group?.references[key]?.max){
            
            if(!devicesWarm[params.node.allLeafChildren[0].data.group.deviceId]){
                devicesWarm[params.node.allLeafChildren[0].data.group.deviceId] = Date.now();
                updatePageTitle(devicesWarm);
            }
            devicesWarmLineColor[params.node.allLeafChildren[0].data.id] = params.node.allLeafChildren[0].data.group.colors_palette?.max_class;
            
            return  {
                component: () => upMaxTagLabel(params.value.value, null, params.node.allLeafChildren[0].data.group.references[key], 
                    params.node.allLeafChildren[0].data.group.references[key].max, key, 
                    params.node.allLeafChildren[0].data.group.colors_palette?.max)
            }
        // Valor abaixo do Min
        } else if (params.node.allLeafChildren[0].data.group.references !== undefined
            && params.node.allLeafChildren[0].data.group.references[key] !== undefined
            && params.node.allLeafChildren[0].data.group?.references[key]?.min !== null
            && params.value.value < params.node.allLeafChildren[0].data.group?.references[key]?.min) {

            if(!devicesWarm[params.node.allLeafChildren[0].data.group.deviceId]){
                devicesWarm[params.node.allLeafChildren[0].data.group.deviceId] = Date.now();
                updatePageTitle(devicesWarm);
            }
            devicesWarmLineColor[params.node.allLeafChildren[0].data.id] = params.node.allLeafChildren[0].data.group.colors_palette?.min_class;
            
            return  {
                component: () => upMaxTagLabel(params.value.value, null, params.node.allLeafChildren[0].data.group.references[key], params.node.allLeafChildren[0].data.group.references[key].min, key, params.node.allLeafChildren[0].data.group.colors_palette?.min)
            }

        }else{
            if(devicesWarm[params.node.allLeafChildren[0].data.group.deviceId]){
                devicesWarm[params.node.allLeafChildren[0].data.group.deviceId] = false;
                updatePageTitle(devicesWarm);
            }
            devicesWarmLineColor[params.node.allLeafChildren[0].data.id] = null;
        }
    }
    // Linha
    if(params?.value && typeof params.value === "number" && params.data.group?.references && params.data.group.references[key] ){
        
        if(params.data.group.controleSistema === 0
            && params.data.group.colors_palette?.main_reference_control_off){
            console.log("control off:", params.data.group);
            return  {
                component: () => upMaxTagLabel(params.value, null, params.data.group.references[key], 
                    params.data.group.references[key].max, key, 
                    params.data.group.colors_palette?.main_reference_control_off)
            }
        }
        // Valor acima do Max
        if(params.data.group.references[key]?.max && params.value > params.data.group.references[key].max){
              
            if(!lineWarn[params.data.id]){
                devicesWarm[params.data.group.deviceId] = Date.now();
                lineWarn[params.data.id] = Date.now();
                updatePageTitle(devicesWarm);
            }
            devicesWarmLineColor[params.data.id] = params.data.group.colors_palette?.max_class;
            return  {
                component: () => upMaxTagLabel(params.value, null, params.data.group.references[key], params.data.group.references[key].max, key, params.data.group.colors_palette?.max)
            }

        } else if (params.data.group.references[key]?.min && params.value < params.data.group.references[key].min) {
        
            if(!lineWarn[params.data.id]){
                devicesWarm[params.data.group.deviceId] = Date.now();
                lineWarn[params.data.id] = Date.now();
                updatePageTitle(devicesWarm);
            }
            devicesWarmLineColor[params.data.id] = params.data.group.colors_palette?.min_class;
            return  {
                component: () => upMaxTagLabel(params.value, null, params.data.group.references[key], params.data.group.references[key].min, key, params.data.group.colors_palette?.min)
            }
            
        }else{
            if(devicesWarm[params.data.group.deviceId]){
                devicesWarm[params.data.group.deviceId] = false;
                updatePageTitle(devicesWarm);
            }
            lineWarn[params.data.id] = false;
            devicesWarmLineColor[params.data.id] = null;
        }

    }
}

function updatePageTitle(warms){
    let count = 0;
    for(const device in warms){
        if(warms[device]){
            count++;
        }
    }
    if(count > 0){
        document.title = "("+count.toString()+") Alertas - "+MONITOR_TITLE;
    }else{
        document.title = MONITOR_TITLE;
    }
}


export function isGroup(params){
    if(!params?.value){
        return true;
    }else{
        return false;
    }
}

export function isLine(params){
    if(params?.data){
        return params.data;
    }else{
        return false;
    }
}


export function isLeafGroupList(params){
    if(params?.node && params.node?.allLeafChildren){
        return params.node?.allLeafChildren;
    }else{
        return false;
    }
}


export function isOffiline(group){
    if(group.deviceState === "offline"){
        return true;
    }else{
        return false;
    }
}


export function isNoState(group){
    if(group.deviceState === "no_state"){
        return true;
    }else{
        return false;
    }
}


export function isLeafGroupWarning(params){
    if(params?.node.allLeafChildren[0].data.group.warning > 0){
        const leafs = isLeafGroupList(params);
        let totalWarning = 0;
        for(const line of leafs){
            if(line.data.warning.length > 0){
                totalWarning++;
            }
        }
        return totalWarning;
    }else{
        return 0;
    }
}


export function isLineWarning(line){
    if(line.warning.length > 0){
        return true;
    }else{
        return false;
    }
}


export function isUpMax(item) {
    for(const key in item.references){
        if(typeof item.references[key].max === "number" 
                && typeof item[key] === "number" 
                && item[key] > item.references[key].max){
            return true
        }
    }
    return false;  
}
