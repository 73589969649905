import { getIconeClass, getOfflineTime, hideTagLabel, numberCellFormatter, 
    tagLabel, textLabel, offlineTagLabel, warningGroupTagLabel, warningTagLabel, 
    engieDataExtractor, engieTagLabel, upMaxTag, isGroup, isLine,
    isLeafGroupList, isOffiline, isLeafGroupWarning, isLineWarning, 
    devicesWarmLineColor,  
    lineWarn,
    isNoState,
    noStateTagLabel} from '../components/LayoutComponents';


function columnDefsModel(dataViewModel){
        
    const columnDefs = [];

    const columns_list = dataViewModel.columns

    for(const {type, field, headerName, minWidth, width} of columns_list){
        if(type === "group"){
            columnDefs.push(addColumnGroup(field, headerName, minWidth));
        }
        if(type === "status"){
            columnDefs.push(addStatusColumn(field, headerName, minWidth));
        }
        if(type === "status_agg"){
            columnDefs.push(addStatusColumnAgg(field, headerName));
        }
        if(type === "reference"){
            columnDefs.push(addReferenceColumn(field, headerName));
        }
        if(type === "reference_agg"){
            columnDefs.push(addReferenceColumnAgg(field, headerName));
        }
        if(type === "text_group"){
            columnDefs.push(addGroupTextColumn(field, headerName, minWidth));
        }
        if(type === "text"){
            columnDefs.push(addTextColumn(field, headerName, width));
        }
        if(type === "numeric"){
            columnDefs.push(addNumericColumn(field, headerName));
        }
        if(type === "numeric_diff"){
            columnDefs.push(addNumericDiffColumn(field, headerName));
        }
        if(type === "range_time"){
            columnDefs.push(addRangeTimeColumn(field, headerName));
        }
        if(type === "engie"){
            columnDefs.push(
                {
                    headerName,
                    field,
                    minWidth: 85,
                    type: 'rightAligned',
                    aggFunc:'avg',
                    cellRendererSelector: (params) => {
                        const { control, info } = engieDataExtractor(params);
                        return engieTagLabel(control, info);
                    },
                    sortable: true,
                }
            );
        }

    }
    columnDefs.push({field: 'alerta', minWidth: 10, hide: true});
    return columnDefs;
}

const classByAlerts = {
    "smaller": "bg-danger text-white",
    "bigger": "bg-warning text-dark",
    "running": "bg-success text-white",
    "off-line": "bg-secondary text-white",
    "out": "bg-secondary text-white",
    "default": "bg-light text-dark"
};


const statesNotOffline = {
    'running': true,
    'warning': true
}


function addReferenceColumn(field, headerName) {
    return { 
        headerName, 
        field, 
        type: 'numericColumn', 
        minWidth: 50,
        cellRendererSelector: (params) => {
            if(params?.value === null){
                return hideTagLabel(params.data['real'+field], field);
            }else{
                return upMaxTag(params, field);
            }
        },
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: numberCellFormatter,
        sortable: true
    } 
}


function addReferenceColumnAgg(field, headerName) {
    return { 
        headerName, 
        field, 
        type: 'numericColumn', 
        minWidth: 50,
        aggFunc:'avg',
        cellRendererSelector: (params) => {
            if(params?.value === null){
                return hideTagLabel(params.data['real'+field], field);
            }else{
                return upMaxTag(params, field);
            }
        },
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        valueFormatter: numberCellFormatter,
        sortable: true
    } 
}


function addGroupTextColumn(field, headerName, width) {
    return {
        headerName,
        field: 'group.'+field,
        minWidth:50,
        width:width,
        cellRendererSelector: (params) => {
            if(isGroup(params)){
                const leafs = isLeafGroupList(params);
                return textLabel(leafs[0].data.group[field], 'Atualizado em: ' + leafs[0].data.atualizado_br);
            }
        },
        sortable: true
    }
}


function addTextColumn(field, headerName, width) {
    return {
        headerName,
        field,
        width,
        minWidth:width,
        sortable: true
    }
}


function addColumnGroup(field, headerName, minWidth) {
    return {
        headerName,
        field,
        enableRowGroup: true,
        rowGroup: true,
        hide: true,
        showRowGroup: false,
        width:minWidth,
    }
}


function addNumericDiffColumn(field, headerName) {
    return {
        headerName,
        field,
        minWidth: 50,
        width: 50,
        cellRenderer: 'agAnimateShowChangeCellRenderer',
        type: 'numericColumn',
        valueFormatter: diferencaFormatter,
        sortable: true
    }
}


function addNumericColumn(field, headerName) {
    return {
        headerName,
        field,
        width: 20,
        type: 'numericColumn', 
        sortable: true
    }
}


function addRangeTimeColumn(field, headerName) {
    return {
        headerName,
        field,
        width: 20,
        valueGetter: getOfflineTime,
        sortable: true
    }
}


function addStatusColumnAgg(field, headerName) {
    return {
        headerName,
        field,
        minWidth: 50,
        cellRendererSelector: (params) => {
            
            if(isGroup(params)){
                if(isLeafGroupList(params)){
                    const leafs = isLeafGroupList(params);
                    if(isOffiline(leafs[0].data.group)){
                        let msgInfo = 'Atualizado em: ' + leafs[0].data.atualizado_br ;
                        return offlineTagLabel(msgInfo, leafs[0].data.group.deviceStateAt);
                    }
                    
                    const totalWarning = isLeafGroupWarning(params);
                    if(totalWarning){
                        return warningGroupTagLabel(totalWarning);
                    }
                }
            }
    
            if(isLine(params)){
                const line = isLine(params);
                let msgInfo = 'Atualizado em: ' + line.atualizado_br ;
                if(isOffiline(line.group)){
                    return offlineTagLabel(msgInfo, line.group.deviceStateAt);
                }
                if(isLineWarning(line)){
                    return warningTagLabel(msgInfo, line.warning);
                }
                return tagLabel("info", msgInfo);
            }
    
        },
        sortable: true,
        cellClass: getIconeClass
    }
}


function addStatusColumn(field, headerName, minWidth) {
    return {
        headerName,
        field,
        width:minWidth,
        cellRendererSelector: (params) => {
            if(isLine(params)){
                const line = isLine(params);
                const msgInfo = 'Atualizado em: ' + line.atualizado_br ;
                if(isOffiline(line.group)){
                    return offlineTagLabel(msgInfo, line.group.deviceStateAt);
                }
                if(isNoState(line.group)){
                    return noStateTagLabel(msgInfo, line.group.deviceStateAt);
                }
                if(isLineWarning(line)){
                    return warningTagLabel(msgInfo, line.warning);
                }
                return tagLabel("info", msgInfo);
            }
        },
        sortable: true,
        cellClass: getIconeClass
    }
}


function getRowClass(params) {
    let res =  classByAlerts["default"];
    if (params.data) {
        if (!statesNotOffline[params.data.group.deviceState]) {
            res = classByAlerts["off-line"];
        } else {
            res = classByAlerts[params.data.alerta];
            if (!res) {

                if(typeof params.data.group?.controleSistema === "number" && params.data.group.controleSistema === 1){
                    res = classByAlerts["running"];

                    if(lineWarn[params.data.id]
                            && devicesWarmLineColor[params.data.id]
                            && typeof lineWarn[params.data.id] === "number"
                            && Math.round((Date.now() - lineWarn[params.data.id])/1000) > 60 ){
           
                        res = classByAlerts[devicesWarmLineColor[params.data.id]];
                        
                    }

                }

            }
        }
    }
    return res;
}


function diferencaFormatter(params) {
    const valor = (Math.round(params.value * 100) / 100)
    let prefix;
    if (valor > 0) {
        prefix = "+";
    } else  {
        prefix = "";
    }
    return prefix + valor;
}


export const gridOptionsModel = (dataViewModel) => {
    console.log("dataViewModel?.group_header_name:", dataViewModel?.group_header_name);
    const gHeaderName = dataViewModel?.group_header_name ? dataViewModel.group_header_name : dataViewModel.group_id;
    const gWidth = dataViewModel?.group_width ? dataViewModel.group_width : 340;
    console.log({gHeaderName, gWidth});
    return {
        asyncTransactionWaitMillis: 500,
        groupUseEntireRow: true,
        animateRows:true,
        autoGroupColumnDef:{
            headerName: gHeaderName,
            width: gWidth,
            maxWidth: gWidth,
        },
        columnDefs: columnDefsModel(dataViewModel),
        groupDefaultExpanded: dataViewModel.groupDefaultExpanded,
        rowGroupPanelShow: 'always',
        suppressMakeColumnVisibleAfterUnGroup: false,
        suppressDragLeaveHidesColumns: false,
        showOpenedGroup: false,
        
        getRowNodeId: function(data) {
            return data.id; 
        },
        getRowClass: getRowClass,
        defaultColDef: {
            resizable: true,
            flex: 1
        },
        suppressRowHoverHighlight:true
    }

};
  


// function isUpMax(item) {
//     for(const key in item.references){
//         if(typeof item.references[key].max === "number" 
//                 && typeof item[key] === "number" 
//                 && item[key] > item.references[key].max){
//             return true
//         }
//     }
//     return false;  
// }