import packageJson from './../../package.json';

const monitorTitle = process.env.REACT_APP_MONITOR_TITLE;
const HASURA_GRAPHQL_ENGINE_HOSTNAME = process.env.REACT_APP_HASURA_GRAPHQL_ENGINE_HOSTNAME;
const headers = { "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SKEY };
const connectionParams = { headers };

export const MONITOR_TITLE = monitorTitle+" v"+packageJson?.version;
export const MINUTES = 60000;
export const fetchPolicy = "no-cache";
export const wsurl = `wss://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
export const httpurl = `https://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
export const apolloOptions = { reconnect: true, connectionParams };
export const httpLinkOptions = {  uri: httpurl, headers };
export const webSocketOptions = { uri: wsurl, options: apolloOptions };
export const modelsApi = process.env.REACT_APP_MODELS_API_URL;


console.log(`\n\n\n\n\n\n`);
console.log("*****************************************************");
console.log("*************** ", MONITOR_TITLE, " ***************");
console.log("*****************************************************");
console.log("************* Itaueira Agropecuária S.A *************");
console.log("*****************************************************");
console.log(`\n\n\n\n\n\n`);
