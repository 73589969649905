import axios from 'axios';

class Models{
    userId;
    models;

    constructor (userId, modelsApi) {
        this.userId = userId;
        this.modelsApi = modelsApi;
    }

    
    async getModels(){
        const { url, headers } = this.apiConfig();
        console.log("models url", url);
        await axios.get(url, {headers}).then((res) => {
            console.log("res api model", res.data);
            this.models = res.data;
        }).catch((err) => console.error(err));
        console.log("res models", this.models);
        return this.models;
    }

    apiConfig(){
        return { 
            "url":`${this.modelsApi}${this.userId}`, 
            "headers": {
                'Access-Control-Allow-Origin': '*'
            }
        }
    }

}

export {Models};