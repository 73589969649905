import React , { useState, useEffect } from 'react';
import DataView from "./components/DataView";
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { MainIcon } from './components/LayoutComponents';
import { MONITOR_TITLE, modelsApi } from './utils/constants';
import { Authorization } from './services/Authorization';
import { Models } from './services/Models';
import { gridOptionsModel } from './configs/dataViewOptions';


function Auth() {
    
    const [ profile, setProfile ] = useState();
    const [ model, setModel ] = useState();
    const [ columnsModel, setColumnsModel ] = useState();
    const [ menu, setMenu ] = useState([]);
    const [ gridOptions, setGridOptions ] = useState();
    const [ apiTime, setApiTime ] = useState(0);

   
    useEffect(() => {
        console.log("New model", model?.name);
        return () => true;
      }, [model, menu]);

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            const authorization = new Authorization(codeResponse);
            const responseProfile = await authorization.getProfile();
            setProfile(responseProfile);
            setApiTime(Date.now());
            const models = new Models(responseProfile.email, modelsApi);
            const responseModels = await models.getModels();
            if(responseModels?.models){
                setColumnsModel(responseModels.models);
                let newMenu  = []

                for(const key in responseModels.models){
                    newMenu.push({
                        key,
                        "label":key+" - "+responseModels.models[key].name
                    });
                }

                setMenu(newMenu);
            }
            console.log({ 
                "model":model, 
                "gridOptions":gridOptions,
                "models": responseModels 
            });
        } ,
        onError: (error) => console.log('Login Failed:', error)
    });
    
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };
    
    const onModel = (selected) => {
        setGridOptions(undefined);
        const dataViewModel = columnsModel[selected];
        setModel(dataViewModel);
        const dataViewgridOptions = gridOptionsModel(dataViewModel);
        setGridOptions(dataViewgridOptions);
    };

    const NavbarMonitor = ({profileInfo}) => {
        return (
            <Navbar bg="light" variant="light" expand="md" 
                    className="bg-light justify-content-between">
                <Navbar.Brand href="#home">
                <Button variant={"light"}  size="sm" style={{marginTop:-8}}
                        onClick={() => {setGridOptions(undefined);setModel(undefined);}}>
         
                    <MainIcon name="backNavBar"/>
                </Button>
                    <b>
                        {MONITOR_TITLE}
                    </b> 
                    <font style={{color:model?.color, marginLeft:10,}} >
                        {model?.name}
                    </font> 
                </Navbar.Brand>
                <Navbar.Brand href="#home">
                    <UserProfileLabel profileInfo={profileInfo} />
                </Navbar.Brand>
            </Navbar>
        );
    };


    const UserProfileLabel = ({profileInfo}) => {
        return (
            <div>
                <b style={styles.nameProfile} >
                    {profileInfo.name}
                    <Button variant="light" title="Sair"  onClick={logOut}  
                            size="sm" style={{marginLeft:20,marginTop:15,}} >
                        <MainIcon name="logout"/>
                    </Button>
                </b>
                <div style={styles.emailProfile} >
                    {profileInfo.email}
                </div>
            </div>
        )
    };


    const AuthForm = () => {
        return (
            <div style={styles.authFormView} >
                <h3>{MONITOR_TITLE}</h3>
                <br/>
                <Button variant="outline-primary" onClick={() => login()}>
                    <MainIcon name="google"/>
                    Entrar com sua conta Google
                </Button>
            </div>
        )
    };


    const MenuTitle = ({total_items}) => {
        
        if(total_items === 1){
            onModel(menu[0].key);
        }

        const renderStyle = {
            fontColor: total_items > 0 ? 'black' : 'silver',
            text: total_items > 0 ? 'Selecione o monitor' : 'Carregando perfil...'
        }
        return (
           <>
                <h4 style={{color:renderStyle.fontColor}} >
                    {renderStyle.text}
                </h4>
                <br/>
           </>
        )
    };

    const MenuForm = () => {
        return (
            <div style={styles.authFormView} >
                
                <MenuTitle total_items={menu.length} />

                <ButtonGroup vertical>
                    {(menu.length === 0 && apiTime > 0) &&
                            <Spinner animation="border" variant="secondary" />
                    }

                    {menu.map((btn)=>
                        <Button variant={"outline-primary"} key={btn.key}
                            onClick={() => onModel(btn.key)}>
                            {btn.label}
                        </Button> )}
                                
                </ButtonGroup>

            </div>
        )
    };


    return (
        <>
            {(profile && profile?.email ) ? 
                <>
                    {(!model && !gridOptions) ?
                        <MenuForm />
                    :
                        <>
                            <NavbarMonitor profileInfo={profile} />
                            <DataView selected={{ "model":model, "gridOptions":gridOptions }} />
                        </>
                    }
                </>
             : 
                <AuthForm />
            }
        </>
    )
}


const styles = {
    "authFormView":{
        height:'100vh',
        display: "flex",
        flexDirection:'column',
        justifyContent: "center",
        alignItems: "center"
    },
    "emailProfile":{
        marginTop:-15, 
        fontSize:12, 
        color:'gray'
    },
    "nameProfile":{
        fontSize:16, 
        color:'gray'
    }
}


export default Auth;